import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['deals'] = {
  meta: null,
  data: null,
  dealsIds: null,
  nearestUpcomingAppt: null,
  loadingMore: false,
  loadingUpdate: false,
  loadingIds: false,
  loadingDeal: false,
  loadingNearestUpcomingAppt: false,
}

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setDeals: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setDealsIds: (state, action) => {
      return {
        ...state,
        dealsIds: [...action.payload],
      }
    },
    addDeals: (state, action) => {
      const oldData = state.data || []
      return {
        ...state,
        ...action.payload,
        data: [...oldData, ...action.payload.data],
      }
    },
    setDeal: (state, action) => {
      const newData = state.data !== null ? JSON.parse(JSON.stringify(state.data)) : []
      if (!!newData.length && newData[action.payload.index].data) {
        newData[action.payload.index].data = action.payload.deal
      }
      return {
        ...state,
        data: newData,
      }
    },
    setNearestUpcomingAppt: (state, action) => {
      return {
        ...state,
        nearestUpcomingAppt: action.payload,
      }
    },
    setLoadingMoreDeals: (state, action) => ({ ...state, loadingMore: action.payload }),
    setLoadingUpdateDeals: (state, action) => ({ ...state, loadingUpdate: action.payload }),
    setLoadingDealsIds: (state, action) => ({ ...state, loadingIds: action.payload }),
    setLoadingDeal: (state, action) => ({ ...state, updatingDeal: action.payload }),
    setLoadingNearestUpcomingAppt: (state, action) => ({ ...state, loadingNearestUpcomingAppt: action.payload }),
  },
})

export const {
  setDeals,
  addDeals,
  setDealsIds,
  setDeal,
  setNearestUpcomingAppt,
  setLoadingMoreDeals,
  setLoadingUpdateDeals,
  setLoadingDealsIds,
  setLoadingDeal,
  setLoadingNearestUpcomingAppt,
} = dealsSlice.actions

export default dealsSlice.reducer
